import React, { useState, useEffect } from "react";
import { metadata } from "./metadata";
import "./App.css";

function cleanAltText(altText) {
	return altText.replace(/\[.*\]/, "").replace(/r\/infographics -/i, "");
}

const Gallery = () => {
	const itemsPerPage = 10;
	const [loadedItems, setLoadedItems] = useState(itemsPerPage);
	const [columns, setColumns] = useState(3);
	const [selectedItem, setSelectedItem] = useState(null);

	const handleScroll = () => {
		const bottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;
		if (bottom) {
			setLoadedItems((prevLoadedItems) => prevLoadedItems + itemsPerPage);
		}
	};

	const handleResize = () => {
		if (window.innerWidth <= 768) {
			setColumns(1);
		} else if (window.innerWidth <= 1024) {
			setColumns(2);
		} else {
			setColumns(3);
		}
	};

	const openLightbox = (id, altText) => {
		setSelectedItem({ id, altText });
	};

	const closeLightbox = () => {
		setSelectedItem(null);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => {
			window.removeEventListener("scroll", handleScroll);
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const getColumns = () => {
		const columnArray = Array.from({ length: columns }, () => []);
		Object.entries(metadata)
			.slice(0, loadedItems)
			.forEach(([id, altText], index) => {
				columnArray[index % columns].push(
					<div
						key={id}
						className="gallery-item"
						onClick={() => openLightbox(id, altText)}
					>
						<img
							src={`images/${id}`}
							alt={cleanAltText(altText)}
							style={{ width: "100%", display: "block" }}
						/>
						<div className="alt-text">{cleanAltText(altText)}</div>
					</div>
				);
			});
		return columnArray;
	};

	return (
		<div className="gallery-container">
			<div className="gallery-grid">
				{getColumns().map((columnItems, index) => (
					<div
						key={index}
						className="gallery-column"
					>
						{columnItems}
					</div>
				))}
			</div>
			{selectedItem && (
				<div
					className="lightbox"
					onClick={closeLightbox}
				>
					<div
						className="lightbox-content"
						onClick={(e) => e.stopPropagation()}
					>
						<img
							src={`images/${selectedItem.id}`}
							alt={cleanAltText(selectedItem.altText)}
							style={{ width: "100%", height: "auto" }}
						/>
						<div className="lightbox-alt-text">{cleanAltText(selectedItem.altText)}</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Gallery;
