export const metadata = {
    "u0lpnenehhhd1.jpeg": "r/Infographics - Radar plot depicting the data presented in Nutt, David, Leslie A King, William Saulsbury, Colin Blakemore. \"Development of a rational scale to assess the harm of drugs of potential misuse\"",
    "igk02xj48ehd1.png": "r/Infographics - 5 Excel Shortcuts to Accelerate your Calculations",
    "0qhskldlwehd1.png": "r/Infographics - Infographic Explaining Olympic Table Tennis at Paris 2024",
    "tpu6ps7snghd1.png": "r/Infographics - Breaking down Novo Nordisk\u2019s Billions $$$",
    "m2t7uj5huahd1.png": "r/Infographics - U.S. Vinyl Record Sales 1973\u20132023 + Top 5 Vinyl Artists in 2023",
    "e589s8pva9hd1.png": "r/Infographics - The Likelihood of Common Side Effects from Antidepressants",
    "at6q1gtyrahd1.jpeg": "r/Infographics - Real Wage growth by Income in the U.S. ",
    "1adycb5q4ahd1.jpeg": "r/Infographics - Comparing Crude Oil Production: U.S. States vs. Global Producers (2023)",
    "hq16ehq4iahd1.png": "r/Infographics - Pedro S\u00e1nchez far exceeds the average Spanish male height of 1.76 meters.",
    "0pymmxpdt6hd1.jpeg": "r/Infographics - 15 Closer Techniques ",
    "3hqstxpdz7hd1.png": "r/Infographics - 5 Steps to Improve Cash Flow",
    "r05sk9pp33hd1.jpeg": "r/Infographics - Non-Homeownership Trends in the U.S. (1970\u20132023)",
    "gcq3lxjna6hd1.jpeg": "r/Infographics - Gastroenteritis Symptoms Causes and Treatment",
    "16olavl7f2hd1.jpeg": "r/Infographics - Crude Oil Production Across U.S. States (2023)",
    "r4hpvoubsehd1.jpeg": "r/Infographics - What is Lasik Surgery? Treatment and Benefits",
    "rctddz5721hd1.jpeg": "r/Infographics - Got an idea for creating 'job pentagons' to help people decide career routes - interested to know peoples thoughts/suggestions? (I am an architect btw - this is how I see my job)",
    "tufkgoiaqwgd1.jpeg": "r/Infographics - Richest suburbs in America ",
    "ivl0myts32hd1.png": "r/Infographics - Debugging Sourdough Crumb Structures",
    "66ybf1ew4tgd1.jpeg": "r/Infographics - Paris 2024 Olympic medal compositions",
    "9e76yteaewgd1.jpeg": "r/Infographics - Top 50 Most Loved Snacks at Costco",
    "84zk7j7odwgd1.png": "r/Infographics - The ranking shows all high-speed lines (speed equal to or greater than 200 km/h) in service."
}